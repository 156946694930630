import React, { useEffect, useState } from 'react'
import { Modal, Table } from 'flowbite-react'

import telegramImg from "../img/telegram.png";
import twitterImg from "../img/twitter.png";
import './ranking.css'

import { AiOutlineTrophy } from 'react-icons/ai'
import { formatAmount, HOST } from '../utils/constant';

const EditInfo = ({ rankingModal, setRankingModal }) => {
  const [rankingData, setRankingData] = useState(null)

  useEffect(() => {
    const fetchRankingData = async () => {
      try {
        let response = await fetch(`${HOST}/api/users/getUsers`)
        let data = await response.json()

        // console.log(data)
        const sortedUsers = data.data.sort((a, b) => Number(b.amount) - Number(a.amount));
        setRankingData(sortedUsers)

      } catch (error) {
        console.log(error)
      }
    }

    fetchRankingData()
  }, [])

  return (
    <Modal
      show={rankingModal}
      size="4xl"
      popup={true}
      onClose={() => setRankingModal(false)}

    >
      <Modal.Header>
        <div className='text-[#fff] flex items-center px-3'>
          <AiOutlineTrophy /> &nbsp; $MELON Holders
        </div>
      </Modal.Header>
      <Modal.Body className='bg-[#000]'>
        <section className='overflow-y-auto mt-10' style={{ height: '80vh' }}>
          <Table >
            <Table.Head className='text-center bg-[#252a34] text-white'>
              <Table.HeadCell>Rank</Table.HeadCell>
              <Table.HeadCell>Username</Table.HeadCell>
              <Table.HeadCell>Twitter</Table.HeadCell>
              <Table.HeadCell>Telegram</Table.HeadCell>
              <Table.HeadCell>Amount</Table.HeadCell>
            </Table.Head>
            <Table.Body className="text-center">
              {
                (rankingData && (
                  rankingData.map((ranking, i) => (
                    <Table.Row className="bg-gray-900 dark:bg-gray-800" key={i}>
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        {i + 1}
                      </Table.Cell>
                      <Table.Cell>
                        {ranking.username}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: '-webkit-center' }}>
                        <a href={`${ranking.twitter}`} target='_blank' rel='noreferrer'>
                          <img className='text-center' src={twitterImg} alt="" />
                        </a>
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: '-webkit-center' }}>
                        <a href={`${ranking.telegram}`} target='_blank' rel='noreferrer'>
                          <img className='text-center' src={telegramImg} alt="" />
                        </a>
                      </Table.Cell>
                      <Table.Cell>
                        {formatAmount(ranking.amount)} $MELON
                      </Table.Cell>
                    </Table.Row>
                  ))

                ))
              }
            </Table.Body>
          </Table>
        </section>


      </Modal.Body>

    </Modal>
  )
}

export default EditInfo