import React, { useEffect, useState } from 'react'
import { Modal, Label, TextInput, Textarea } from 'flowbite-react'
import okGif from '../img/ok.gif'
import wrongGif from '../img/wrong.gif'
import loadingGif from '../img/loading.gif'
import { getRandomLatLong, HOST } from '../utils/constant'

const EditInfo = ({ modalVisible, setAlerto, setAlertoInfo, ribbitData, userInformation, setModalVisible, userLngLat, currentAccount }) => {

  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState({
    lng: (userLngLat) ? userLngLat[0] : -35.5234,
    lat: (userLngLat) ? userLngLat[1] : 39.4324,
    username: (userInformation) ? userInformation.username : '',
    address: currentAccount,
    telegram: (userInformation) ? userInformation.telegram : '',
    twitter: (userInformation) ? userInformation.twitter : '',
    description: (userInformation) ? userInformation.description : ''
  })

  useEffect(() => {
    setIsLoading(false)
    setFormData({
      lng: (userLngLat) ? userLngLat[0] : -35.5234,
      lat: (userLngLat) ? userLngLat[1] : 39.4324,
      username: (userInformation) ? userInformation.username : '',
      address: currentAccount,
      amount: ribbitData.amount,
      telegram: (userInformation) ? userInformation.telegram : '',
      twitter: (userInformation) ? userInformation.twitter : '',
      description: (userInformation) ? userInformation.description : ''
    })
  }, [userLngLat, currentAccount, userInformation])

  function handleChange(evt) {
    const value = evt.target.value;
    setFormData((prevState) => ({ ...prevState, [evt.target.name]: value }))
  }
  function handleSubmit(e) {
    if (formData.lng === -35.5234 && formData.lat === 39.4324) {
      let RndlngLat = getRandomLatLong()
      formData.coordinates = [RndlngLat.long, RndlngLat.lat]

    } else {
      formData.coordinates = [formData.lng, formData.lat]
    }

    console.log(formData)

    setAlerto(true)
    setAlertoInfo([loadingGif, 'Updating your information & location.'])
    setIsLoading(true)

    if (formData.username === '' || formData.description === '') {
      setIsLoading(false)
      setAlertoInfo([wrongGif, 'Please fill in the form completely.'])
      setTimeout(() => {
        setAlerto(false)
      }, 2000);
    } else {
      const sendData = async () => {
        try {
          let response = await fetch(`${HOST}/api/users/addTheUser`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json"
            },
            body: JSON.stringify(formData).replaceAll(`'`, '')
          })
          let data = await response.json()
          console.log(data)


          setAlertoInfo([okGif, 'Data, & Location Updated successfully'])
          setModalVisible(false)
          window.location.reload(false)
        } catch (error) {
          setAlertoInfo([wrongGif, 'An error occured, try again !'])
          console.log(error)
          setIsLoading(false)
          setTimeout(() => {
            setAlerto(false)

          }, 1000);
        }

      }
      sendData()
    }

  }

  return (
    <Modal
      show={modalVisible}
      size="xl"
      popup={true}
      onClose={() => setModalVisible(false)}
    >
      <Modal.Header />
      <Modal.Body>
        <div className="space-y-6 px-4 pb-4 sm:pb-6 lg:px-2 xl:pb-8 text-white">
          <h3 className="text-xl font-medium text-white">
            Save your Information and location.
          </h3>
          <div>
            <div className="flex gap-2 items-center">
              <div>
                <Label htmlFor="lngInput" >
                  Longitude
                </Label>
                <div className="mb-2">
                  <TextInput
                    type="text"
                    id="lngInput"
                    name='lng'
                    value={formData.lng}
                    readOnly={true}
                    disabled={true}
                    className="mb-2"
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="latInput">
                  Latitude
                </Label>
                <div className="mb-2">
                  <TextInput
                    type="text"
                    id="latInput"
                    name='lat'
                    disabled={true}
                    value={formData.lat}
                    readOnly={true}

                  />
                </div>
              </div>
            </div>
            <Label
              htmlFor="address"
              value="Wallet Address"
            />
            <div className="mb-2">
              <TextInput
                id="address"
                value={formData.address}
                name='address'
                readOnly={true}
                disabled={true}
              />
            </div>

            <div>
              <Label
                htmlFor="username"
                value="Username"
              />
              <div className="mb-2">
                <TextInput
                  id="username"
                  name='username'
                  onChange={handleChange}
                  defaultValue={formData.username}
                  placeholder="Enter Username"
                  required={true}
                />
              </div>
            </div>

            <Label
              htmlFor="telegram"
              value="Telegram Profile Url (Optional)"
            />
            <div className="mb-2">
              <TextInput
                id="telegram"
                name='telegram'
                onChange={handleChange}
                defaultValue={formData.telegram}
                placeholder="https://t.me/username"
              // required={true}
              />
            </div>

            <Label
              htmlFor="twitter"
              value="Twitter Profile Url (Optional)"
            />
            <div className="mb-2">
              <TextInput
                id="twitter"
                name='twitter'
                onChange={handleChange}
                defaultValue={formData.twitter}
                placeholder="https://x.com/username"
              // required={true}
              />
            </div>

            <Label
              htmlFor="description"
              value="Description"
            />
            <div className="mb-2">
              <Textarea
                id="description"
                name='description'
                onChange={handleChange}
                defaultValue={formData.description}
                placeholder="short description about you"
                required={true}
              />
            </div>
          </div>


          <div className="w-full">
            {(isLoading) ? (
              <button disabled={true} className='bg-[#e49665] w-full px-4 py-3 rounded-md text-white'>
                Save Your Info
              </button>
            ) : (
              <button onClick={handleSubmit} className='bg-[#9D7E49] w-full px-4 py-3 rounded-md text-white'>
                Save Your Info
              </button>
            )}

          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default EditInfo