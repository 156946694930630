import React, { useEffect, useState } from 'react'
import logo from '../logo.webp'

import Ranking from './Ranking'

import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";

function Header() {
  const [rankingModal, setRankingModal] = useState(false)
  const [toggleMenu, setToggleMenu] = React.useState(false);

  useEffect(() => {
    setRankingModal(false)
  }, [])

  return (
    <>
      <div className="absolute z-10 w-full text-[#9D7E49] md:p-5 p-2">
        <div className="flex justify-between items-center">


          <div className="flex items-center gap-x-2">
            <img src={logo} className="w-10 rounded-full" alt='' />
            <p href="#" className='mb-0 headersBtn'>Melon Map</p>
          </div>

          <div className='flex gap-5 items-center'>
            {!toggleMenu && (
              <GiHamburgerMenu fontSize={28} className="text-[#9D7E49] md:hidden cursor-pointer" onClick={() => setToggleMenu(true)} />
            )}
            {toggleMenu && (
              <AiOutlineClose fontSize={28} className="text-white md:hidden cursor-pointer" onClick={() => setToggleMenu(false)} />
            )}
            {toggleMenu && (
              <ul
                className="z-10 fixed -top-0 -right-2 p-3 w-[70vw] h-screen shadow-2xl md:hidden list-none
                flex flex-col justify-start items-start rounded-md blue-glassmorphism animate-slide-in"
              >
                <li className="text-xl w-full my-2 mb-3"><AiOutlineClose onClick={() => setToggleMenu(false)} /></li>
                <hr />
                <button className='text-[#9D7E49] mb-3'
                  onClick={() => setRankingModal(true)}
                >Holders</button>
                <a href='https://jup.ag/swap/SOL-MELON' rel='noreferrer' target='_blank' className='text-[#9D7E49] mb-3'>Buy $MELON</a>
                <button disabled={true} className='text-[#9D7E49] mb-3'>About</button>
              </ul>
            )}
            <div className='md:flex hidden items-center gap-6'>
              <button  className='headersBtn' onClick={() => setRankingModal(true)}>Holders</button>
              <a href='https://jup.ag/swap/SOL-MELON' rel='noreferrer' target='_blank' className='headersBtn'>Buy $MELON</a>
              <button disabled={true} className='headersBtn'>About</button>
            </div>

          </div>

          <WalletMultiButton />
        </div>
      </div>

      <Ranking rankingModal={rankingModal} setRankingModal={setRankingModal} />
    </>
  )
}

export default Header