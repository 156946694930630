import React, { useEffect, useState, useRef } from 'react'
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import EditInfo from './components/EditInfo';

import telegramImg from "./img/telegram.png";
import twitterImg from "./img/twitter.png";
import dogImg from './img/userpin.png'
import okGif from './img/ok.gif'
import wrongGif from './img/wrong.gif'
import loadingGif from './img/loading.gif'
import ribbit2 from './img/userpin.png'

import { FiEdit } from 'react-icons/fi'
import { AiOutlineSave } from 'react-icons/ai'
import { MdOutlineMyLocation } from 'react-icons/md'
import { FaGlobeAfrica, FaMapMarkedAlt } from "react-icons/fa";


import { useWallet } from '@solana/wallet-adapter-react';
import Alert from './components/Alert';
import { HOST, formatAmount, getRandomLatLong } from './utils/constant';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const Mapp = () => {

  const [currentAccount, setcurrentAccount] = useState('')
  const [getRibbit, setGetRibbit] = useState(false);
  const [ribbitData, setRibbitData] = useState([]);
  const [isUser, setIsUser] = useState(false)
  const [editLocBtn, setEditLocBtn] = useState(false);
  const [modalVisible, setModalVisible] = useState(false)
  const [showFirstButton, setShowFirstButton] = useState(true);

  const [alerto, setAlerto] = useState(false)
  const [alertoInfo, setAlertoInfo] = useState([loadingGif, ''])
  const [userLngLat, setUserLngLat] = useState([-35.5234, 39.4324])
  const [userInfo, setUserInfo] = useState(null)
  let { publicKey, connected } = useWallet();

  const [holdersData, setHoldersData] = useState(null)
  // const [zoom, setZoom] = useState(2)

  const map = useRef(null);
  const mapContainer = useRef(null);

  useEffect(() => {
    setGetRibbit(false)
    setAlerto(false)
    setIsUser(false)
    setEditLocBtn(false)

    if (publicKey) {
      setcurrentAccount(publicKey.toBase58())
      const fetchNFTs = async () => {
        let creatorAddress = "7DGJnYfJrYiP5CKBx6wpbu8F5Ya1swdFoesuCrAKCzZc"
        setAlertoInfo([loadingGif, 'Fetching Your $MELON'])
        setAlerto(true)

        try {
          let response = await fetch(`https://solana-gateway.moralis.io/account/mainnet/${publicKey.toBase58()}/portfolio`, {
            headers: {
              "X-API-Key": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjlkNDYxY2Y5LTdmZWUtNDg5Ni1hNmM1LTkxNzBhMDVhZjI5YyIsIm9yZ0lkIjoiMTQyMjE1IiwidXNlcklkIjoiMTQxODYwIiwidHlwZUlkIjoiNjg2M2Y4YzQtMGNkNS00NDBmLTkzODMtYTMxMTA0MzRjZWIzIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2ODE5NjM4MzAsImV4cCI6NDgzNzcyMzgzMH0.6YG3_CIF4qPNkc763qeUG8g4_-Ml-P7joAPfVXcFTU8",
              "Content-Type": "application/json"
            }
          })
          let metaData = await response.json();
          let isUserToken = metaData.tokens.find(ttkon => ttkon.mint === creatorAddress)

          if (isUserToken) {
            setAlerto(true)
            setAlertoInfo([okGif, 'Yay! You are a $MELON Holder'])
            setTimeout(() => {
              setAlerto(false)
            }, 2000);
            setGetRibbit(true)

            setRibbitData(isUserToken)

            let response2 = await fetch(`${HOST}/api/users/${publicKey.toBase58()}`)
            let data2 = await response2.json()

            if (data2.message === "User not found") {
              let generatedCord = getRandomLatLong()

              setUserLngLat([generatedCord.long, generatedCord.lat])

              map.current.jumpTo({ 'center': [generatedCord.long, generatedCord.lat], 'zoom': 4 });

              var marker = new mapboxgl.Marker({
                draggable: true
              })
                .setLngLat([generatedCord.long, generatedCord.lat])
                .addTo(map.current);

              function onDragEnd() {
                const lngLat = marker.getLngLat();
                setUserLngLat([lngLat.lng.toFixed(4), lngLat.lat.toFixed(4)])
              }

              marker.on('dragend', onDragEnd);
              let markerss = document.querySelectorAll('.mapboxgl-marker')
              markerss[markerss.length - 1].style.backgroundImage = `url(${ribbit2})`


            } else {
              setUserLngLat(data2.data.coordinates)
              setIsUser(true)
              setUserInfo(data2.data)
            }


          } else {
            setGetRibbit(false)
            setAlertoInfo([wrongGif, `Oops! You don't own a $Melon`])

            setTimeout(() => {
              setAlerto(false)
            }, 2000);

          }

        } catch (error) {
          setAlerto(true)
          // setAlertoInfo([wrongGif, `An error occured, Try Again !`])
          console.log(error)
          setTimeout(() => {
            setAlerto(false)
          }, 2000);
        }
      }
      fetchNFTs()
    } else {
      setcurrentAccount("")
    }
  }, [connected, publicKey])


  useEffect(() => {
    setShowFirstButton(true)
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/broskixii/cm24n0qqh00eo01p393mf2c91',
      center: [31.980797, 24.827634],
      zoom: 2,
      font: "Alegreya"
    });
    // calling the Data stored in the db 
    const fetchData = async () => {
      try {
        let response = await fetch(`${HOST}/api/users/getUsers`)
        let data = await response.json()
        // console.log(data)
        setHoldersData(data.data)

        data.data.forEach(geoLoc => {
          let hasukiHTML = `
            <div class="popup-container">
              <div class="flex justify-between">
                <p class="text-[#9D7E49] text-lg capitalize">${geoLoc.username} <img class="inline-block w-4" src="${dogImg}" alt="username" /></p>
                <div class="flex items-center">
                  <a href="${geoLoc.telegram}" target="_blank" class="mr-3">
                    <img src="${telegramImg}" alt="Discord" />
                  </a>
                  <a href="${geoLoc.twitter}" target="_blank">
                    <img src="${twitterImg}" alt="Twitter" />
                  </a>
                </div>
              </div>
              <p class='text-left mb-5'>${geoLoc.description}</p>

              <p class="font-bold text-md">Holding: <span class="text-xl text-[#9D7E49]">${formatAmount(geoLoc.amount)} $MELON 🔥</span></p>
            </div>
          `
          var marker

          if (publicKey && geoLoc.address === publicKey?.toBase58()) {

            marker = new mapboxgl.Marker({
              draggable: true
            })
              .setLngLat(geoLoc.coordinates)
              .setPopup(new mapboxgl.Popup({ offset: 30 })
                .setHTML(hasukiHTML))
              .addTo(map.current);
            function onDragEnd() {
              const lngLat = marker.getLngLat();
              setUserLngLat([lngLat.lng.toFixed(4), lngLat.lat.toFixed(4)])
            }

            marker.on('dragend', onDragEnd);
            let markerss = document.querySelectorAll('.mapboxgl-marker')
            markerss[markerss.length - 1].style.backgroundImage = `url(${ribbit2})`

          } else {
            marker = new mapboxgl.Marker()
              .setLngLat(geoLoc.coordinates)
              .setPopup(new mapboxgl.Popup({ offset: 30 })
                .setHTML(hasukiHTML))
              .addTo(map.current);

          }

        })

      } catch (error) {
        console.log(error)
      }
    }
    fetchData()

    map.current.addControl(new mapboxgl.NavigationControl(), "bottom-right");
    return () => map.current.remove();

  }, [connected, publicKey])


  const handleFirstButtonClick = () => {
    setShowFirstButton(false);
    map.current.setStyle("mapbox://styles/broskixii/cm24nkha800es01p315rt7ju9")
    map.current.jumpTo({ 'center': [0, 0], 'zoom': 3 })
  };

  const handleSecondButtonClick = () => {
    setShowFirstButton(true);
    map.current.setStyle("mapbox://styles/broskixii/cm24n0qqh00eo01p393mf2c91")
    map.current.jumpTo({ center: [31.980797, 24.827634], zoom: 2 })
  };



  const zoomOnLocation = () => {
    map.current.jumpTo({ 'center': userLngLat, 'zoom': 4 });
  }

  return (
    <div>
      {alerto && (
        <Alert img={alertoInfo[0]} message={alertoInfo[1]} />
      )}
      <div ref={mapContainer} style={{ width: "100%", height: "100vh" }}></div>

      {getRibbit && (
        <div className='absolute w-full bottom-3'>
          <div className='flex justify-center'>

            <div className='flex gap-2 items-center'>
              <button
                onClick={() => setModalVisible(true)}
                className='bg-[#9D7E49] sm:text-base text-sm sm:px-4 sm:py-3 px-3 py-2 text-white rounded-sm'>
                <AiOutlineSave className='inline' /> Save Location
              </button>
              <button
                onClick={zoomOnLocation}
                className='bg-[#9D7E49] sm:px-4 sm:py-3 px-3 py-2 text-white rounded-full'>
                <MdOutlineMyLocation className='inline' />

              </button>
            </div>
          </div>
        </div>
      )}

      <EditInfo
        setModalVisible={setModalVisible}
        modalVisible={modalVisible}
        userLngLat={userLngLat}
        currentAccount={currentAccount}
        ribbitData={ribbitData}
        userInformation={userInfo}
        setAlerto={setAlerto}
        setAlertoInfo={setAlertoInfo}
      />

      <div className='absolute bottom-10 left-5'>
        {showFirstButton ? (
          <button
            className='bg-[#9D7E49] text-white border-2 border-white rounded-md p-4'
            onClick={handleFirstButtonClick}
          >
            <FaMapMarkedAlt size={20} />
          </button>
        ) : (
          <button
            className='bg-[#9D7E49] text-white border-2 border-white rounded-md p-4'
            onClick={handleSecondButtonClick}
          >
            <FaGlobeAfrica size={20} />
          </button>
        )}
      </div>


    </div>
  )
}

export default Mapp